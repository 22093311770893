import {Button, Form, Grid, Icon} from "semantic-ui-react";
import React from "react";

export default function WeekBrowseControl(props){

  const {previousWeek, firstIsoWeek, changeIsoWeek, nextWeek, disabled} = props
  return <>
    <Grid.Column width={1}>
      <Button onClick={previousWeek} basic icon disabled={disabled}>
        <Icon name='arrow left'/>
      </Button>
    </Grid.Column>
    <Grid.Column width={4}>
        <Form.Input name='isoWeek' value={firstIsoWeek}
                    onChange={changeIsoWeek} type="week" disabled={disabled}/>
    </Grid.Column>
    <Grid.Column width={1}>
      <Button onClick={nextWeek} basic icon disabled={disabled}>
        <Icon name='arrow right'/>
      </Button>
    </Grid.Column>
  </>
}

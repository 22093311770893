import React from 'react'
import {Link} from 'react-router-dom'
import {Dropdown, Table} from "semantic-ui-react";
import FormatedDay from "../core/shared/FormatedDay";
import * as Moment from 'moment';
import moment from 'moment';
import {extendMoment} from 'moment-range'

const extMoment = extendMoment(Moment);

export default function ServicesTable(props) {
  const calcDates = (isoWeek) => {
    const start = moment(isoWeek).startOf('isoWeek').format('YYYY-MM-DD')
    const end = moment(isoWeek).endOf('isoWeek').format('YYYY-MM-DD')
    const range = extMoment.range(start, end)
    let dates = Array.from(range.by("days"));
    return dates;
  }

  const generateTable = (isoWeek) => {
    const dates = calcDates(isoWeek)

    return <Table celled definition key={isoWeek} fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{isoWeek}</Table.HeaderCell>
          {dates.map((day, index) => getHeaderCell(day, index))}
        </Table.Row>
      </Table.Header>
      {generateTableBody(dates)}
    </Table>

  }

  const getHeaderCell = (day, index) => {
    const {public_holidays, school_holidays} = props
    let public_holiday_text = ''
    let feiertag = undefined
    if (public_holidays != undefined) {
      feiertag = public_holidays.find(holiday => holiday.day == day.format("YYYY-MM-DD"))
    }
    let schulfrei = undefined
    if (school_holidays != undefined) {
      schulfrei = school_holidays.find(holiday => holiday.day == day.format("YYYY-MM-DD"))
    }
    let bgStyle = {}

    if (feiertag != undefined) {
      bgStyle = {background: '#FFF5C1'}
      public_holiday_text = <p><i>{feiertag.name}</i></p>
    } else if (schulfrei != undefined) {
      bgStyle = {background: '#ACFFC8'}
      public_holiday_text = <p><i>{schulfrei.name}</i></p>
    }
    return <Table.HeaderCell key={index} style={bgStyle}>
      <FormatedDay day={day.format("dddd")}/> {day.format("DD.MM.YYYY")}
      {public_holiday_text}
    </Table.HeaderCell>

  }

  const generateTableBody = (dates) => {
    const {staffs} = props
    return <Table.Body>
      {staffs.map(staff => <Table.Row key={staff.id} style={{}}>
          <Table.Cell title={staff.id}>{staff.name}</Table.Cell>
          {dates.map(day => getBodyCell(staff, day)
          )}
        </Table.Row>
      )}
    </Table.Body>;
  }

  const getBodyCell = (staff, day) => {
    const {handlePlanDropdownChange, editable, roster} = props
    const staff_id = staff.id
    const formatedDay = day.format("YYYY-MM-DD")
    const staff_id_day = `${staff_id}-${formatedDay}`

    let options = []
    if (roster !== undefined) {
      options = roster.unassignedOptions(staff_id, formatedDay)
    }

    let content = []
    if (editable === true) {
      const values = roster.assignmentsFor(staff_id_day)
      content.push(<Dropdown placeholder="" key={staff_id_day} name={staff_id_day} value={values}
                             onChange={handlePlanDropdownChange} fluid search selection clearable multiple
                             options={options}
        />
      )
      return <Table.Cell key={staff_id_day} style={{overflow: "visible", padding: 0}}>
        {content}
      </Table.Cell>;
    } else {
      const values = getValuesOfPlan(staff_id_day) // getValuesOfPlan
      values.forEach((value) => {
        const id = value.id
        const matchcode = value.matchcode
        const variable_working_time = value.variable_working_time
        const entry_id = value.entry_id
        const type = id.substr(0, id.indexOf('-'))
        const idOfValue = parseInt(id.substr(id.indexOf('-') + 1))

        if (type == "s") {
          content.push(
            <Link key={id} to={`/schedules/${idOfValue}`} style={{marginRight: "0.5em"}}>
              {matchcode}
            </Link>
          )
        } else if (type == "p") {
          if (variable_working_time > 0) {
            content.push(<Link key={`entry-id-${entry_id}`} to={`/services/variable/${entry_id}/edit`}
                               style={{marginRight: "0.5em"}}>
                {matchcode}
              </Link>
            )
          } else {
            content.push(
              <Link key={id} to={`/presettings/${idOfValue}`} style={{marginRight: "0.5em"}}>
                {matchcode}
              </Link>
            )
          }


        }
      })
      return <Table.Cell key={staff_id_day} style={{overflow: "visible", wordWrap: "break-word"}}>
        {content}
      </Table.Cell>;
    }

  }

  const getValuesOfPlan = (id) => {
    const {plan, service_staff_map} = props
    const temp_merged_plan = new Map([...service_staff_map, ...plan]) // todo dies inteligenter mappen, wenns einen key gibt, dann ins array pushen
    if (temp_merged_plan != null) {
      let values = temp_merged_plan.get(id)
      if (values != undefined) {
        return values
      }
    }
    return []
  }

  const {isoWeek} = props

  return <div id='ServiceIsoWeekTable' style={{overflow: "visible", margin: "1em 0"}}>
    {generateTable(isoWeek)}
  </div>
}

export default class DatesGenerator {
  constructor(isoWeeks) {
    this.isoWeeks = isoWeeks;
  }

  // Helper function to get the start date of an ISO week (Monday)
  getStartOfISOWeek(isoYear, isoWeek) {
    const firstDayOfYear = new Date(Date.UTC(isoYear, 0, 4)); // Jan 4 is guaranteed to be in week 1
    const dayOfWeek = firstDayOfYear.getUTCDay();
    const startOfFirstWeek = new Date(firstDayOfYear);
    startOfFirstWeek.setUTCDate(
      firstDayOfYear.getUTCDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    );

    const startOfWeek = new Date(startOfFirstWeek);
    startOfWeek.setUTCDate(startOfFirstWeek.getUTCDate() + (isoWeek - 1) * 7);
    return startOfWeek;
  }

  // Main function to get all dates within the ISO weeks
  getDays() {
    const allDates = [];

    this.isoWeeks.forEach((isoWeekStr) => {
      const [year, week] = isoWeekStr.split("-W").map(Number); // Parse year and week
      const startOfWeek = this.getStartOfISOWeek(year, week);

      for (let i = 0; i < 7; i++) { // Iterate through each day of the week
        const date = new Date(startOfWeek);
        date.setUTCDate(startOfWeek.getUTCDate() + i);
        allDates.push(date.toISOString().split('T')[0]); // Format as YYYY-MM-DD
      }
    });

    return allDates;
  }
}

import React, {useEffect, useState} from 'react'
import {Container, Form, Grid, Header} from "semantic-ui-react";
import moment from 'moment';
import ServicesTable from "./ServicesTable";
import {
  useAddNewServiceWizardMutation,
  useGetPresettingsSelectForServiceQuery,
  useGetPublicHolidaysQuery,
  useGetSchedulesSelectForServiceQuery,
  useGetServiceStaffArrayQuery,
  useGetStaffsQuery
} from "../../lib/api/apiSlice";
import {Cancel, Submit} from "../core/shared/controls";
import {useNavigate} from "react-router-dom";
import WeekBrowseControl from "./WeekBrowseControl";
import WeekIterationControl from "./WeekIterationControl";
import {createServiceSettings} from "../../lib/localStorage/serviceSettings";
import Roster from "../../lib/service/plan/Roster";
import Generator from "../../lib/service/plan/Generator";
import DatesGenerator from "../../lib/service/plan/DatesGenerator";

/*
*
* This class uses a combined Dropdown of schedules (s-id) and presettings (p-id) and stores the values
* in the Map service.
*
 */
export default function ServiceNew(props) {
  const navigate = useNavigate()

  const serviceSettings = createServiceSettings()
  const item = serviceSettings.get()
  const firstIsoWeek = item[0]
  const iteration = item.length


  const servicesNewInitialState = {
    isoWeeks: Array.from({length: iteration}, (_, i) => moment(firstIsoWeek).startOf('isoWeek').add(i, 'week').format('GGGG-[W]WW')),
    plan: new Map(),
    dates: [],
    roster: new Roster()
  }

  const [formData, setFormData] = useState({
    ...servicesNewInitialState
  })

  const {
    data: staffs = [],
  } = useGetStaffsQuery(false)

  const {
    data: service_schedules_select = []
  } = useGetSchedulesSelectForServiceQuery(formData.isoWeeks)

  const {
    data: service_presettings_select = []
  } = useGetPresettingsSelectForServiceQuery()

  const {
    data: service_staff_array = [], isLoading
  } = useGetServiceStaffArrayQuery({isoWeeks: formData.isoWeeks, variable_working_time: false})

  const {
    data: public_holidays = []
  } = useGetPublicHolidaysQuery()

  const getStaffIdFromId = (id) => {
    return parseInt(id.substr(0, id.indexOf('-')))
  }

  const getTypeOfValue = (value) => {
    return value.id.substr(0, value.id.indexOf('-'))
  }

  const getIdOfValue = (value) => {
    return parseInt(value.id.substr(value.id.indexOf('-') + 1))
  }

  const translateBodyOutOfMap = (plan) => {
    let retVal = []

    // create normal entries
    plan.forEach((values, key) => {
      const staff_id = getStaffIdFromId(key)
      const day = getDayFromId(key)
      if (values.length > 0) {
        values.forEach((value) => {
          const type = getTypeOfValue(value)
          const presetting_or_schedule_id = getIdOfValue(value)
          if (type == "p") {
            retVal.push({staff_id: staff_id, day: day, schedule_id: null, presetting_id: presetting_or_schedule_id})
          } else if (type == "s") {
            retVal.push({staff_id: staff_id, day: day, schedule_id: presetting_or_schedule_id, presetting_id: null})
          } else {
            retVal.push({staff_id: staff_id, day: day, schedule_id: null, presetting_id: null})
          }
        })
      } else {
        retVal.push({staff_id: staff_id, day: day, schedule_id: null, presetting_id: null})
      }

    })
    return retVal
  }

  const getDayFromId = (id) => {
    return id.substr(id.indexOf('-') + 1)
  }

  const [addNewServiceWizard] = useAddNewServiceWizardMutation()

  const create = async () => {
    try {
      const temp_merged_plan = new Map([...service_staff_map, ...formData.plan])

      let body = translateBodyOutOfMap(temp_merged_plan)

      await addNewServiceWizard(body).unwrap()
      navigate(`/services`)
    } catch (err) {
      console.log('Failure', err)
    }
  }

  const handlePlanDropdownChange = (e, data) => {
    console.log("OMG, Dropdown changed. e, data", e, data)
    const {plan} = formData
    let {roster} = formData
    let newPlan = plan
    const staff_date_id = data.name // 3-2023-12-01 staff_id + date
    const staff_id = data.name.split("-")[0]
    const day = staff_date_id.split('-').slice(1).join('-');
    const ids = data.value // s-1 or p-1 schedule or presetting corresponding id
    let newValues = []

    ids.forEach(id => {
      const entry = {id: id}
      newValues.push(entry)
    })
    newPlan.set(staff_date_id, newValues)


    roster.assign(staff_id, day, ids)


    setFormData((prevState => ({...prevState, plan: newPlan, roster: roster})))
  }

  useEffect(() => {
    document.title = 'Dienstplan Wizard | First Class bus'

    if (service_schedules_select.length
      && service_presettings_select.length
      && staffs.length
      && service_staff_array.length
    ) {

      const datesGenerator = new DatesGenerator(formData.isoWeeks)
      const days = datesGenerator.getDays()

      let generator = new Generator({
        staffs: staffs,
        days: days,
        schedules: service_schedules_select,
        presettings: service_presettings_select,
        service_staff_array: service_staff_array
      })


      let roster = formData.roster
      roster.initializeDays(days)
      roster.initializeStaffs(generator.staffs)
      roster.initializeAllEntries(generator.allEntries)
      roster.initializeAssignments(generator.assignment_array)
      roster.initializeOpenAssignments(generator.open_assignment_array)

      setFormData(prevState => ({
        ...prevState, roster: roster
      }))

    }

  }, [service_schedules_select, service_presettings_select, staffs, service_staff_array])

  const service_staff_map = new Map(service_staff_array)

  return <Container fluid>

    <Grid style={{marginTop: '1em'}} stackable>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h2">Dienstplan Wizard</Header>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right' className="no-print">
          <Submit onClick={() => create()}/>
          <Cancel/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Form>
      <Grid stackable>
        <Grid.Row>
          <WeekBrowseControl
            firstIsoWeek={firstIsoWeek}
            disabled={true}
          />
          <WeekIterationControl
            iteration={iteration}
            disabled={true}
          />
          <Grid.Column width={4} textAlign='right'>

          </Grid.Column>
        </Grid.Row>
      </Grid>

    </Form>
    <div className='vertical-scrollable' style={{overflow: "visible"}}>
      {!isLoading && formData.isoWeeks.map(isoWeek => <ServicesTable key={isoWeek}
                                                                     isoWeek={isoWeek}
                                                                     staffs={staffs}
                                                                     service_staff_map={service_staff_map}
                                                                     plan={formData.plan}
                                                                     roster={formData.roster}
                                                                     editable={true}
                                                                     handlePlanDropdownChange={handlePlanDropdownChange}
                                                                     public_holidays={public_holidays}/>)}
    </div>
  </Container>

}

export default class Roster {
  constructor() {
    this.days = [] //["2024-11-01", "2024-11-02"]
    this.allEntriesMap = new Map() // key: s-1, value: Entry
    this.assignmentsMap = new Map() // key: staff-id-day, value: ["s-1","p-2"] Entry ids
    this.openAssignmentsMap = new Map() // key: staff-id-day, value: ["s-1", "s-2"]
    this.staffMap = new Map() // key: staff_id, staff
  }

  initializeDays(days) {
    this.days = days
  }

  initializeAllEntries(entries_array) {
    entries_array.forEach(entry => {
      this.allEntriesMap.set(entry.key, entry)
    })
  }

  initializeStaffs(staffs_array) {
    staffs_array.forEach(staff => {
      this.staffMap.set(staff.id, staff)
    })
  }

  initializeAssignments(assignment_array) {
    assignment_array.forEach(element => {
      this.assignmentsMap.set(element[0], element[1])
    })
  }

  initializeOpenAssignments(open_assignment_array) {
    open_assignment_array.forEach(element => {
      this.openAssignmentsMap.set(element[0], element[1])
    })
  }

  unassignedOptions(staff_id, day) {
    const staff = this.staffMap.get(staff_id)
    if (staff) {
      let entries = this.#entriesOn(staff_id, day)
      return this.#to_options(entries)
    } else {
      return [{}]
    }
  }

  assign(staff_id, day, entry_ids) {
    this.assignmentsMap.set(`${staff_id}-${day}`, entry_ids)
  }

  assignmentsFor(staff_id_day){
    const values = this.assignmentsMap.get(staff_id_day)
    if(values){
      return values
    } else {
      return []
    }
  }
  findEntry(entry_id){
    const entry = this.allEntriesMap.get(entry_id)
    return entry
  }

  #entriesOn(staff_id, day) {
    const open = this.#openAssignmentsFor(staff_id, day)
    const assignedToOthers = this.#assignedToOthersEntries(staff_id, day)

    //open entries for staff on day - assigned entries for others on day
    const difference = open.filter(element => !assignedToOthers.includes(element));
    return difference
  }

  #openAssignmentsFor(staff_id, day) {
    const openAssignments = this.openAssignmentsMap.get(`${staff_id}-${day}`)
    if (openAssignments) {
      return openAssignments
    } else {
      return []
    }
  }

  #assignedToOthersEntries(staff_id, day) {
    let otherStaffs = Array.from(this.staffMap.values()).filter(
      staff => staff.id !== parseInt(staff_id)
    );
    let otherEntryIds = []

    otherStaffs.forEach(otherStaff => {
      let assignedEntryIds = this.assignmentsMap.get(`${otherStaff.id}-${day}`)
      if (assignedEntryIds) {
        assignedEntryIds.forEach(entry_id => {
          const entry = this.allEntriesMap.get(entry_id)
          //only push schedules. presettings are excluded, they are not shared
          if(entry.is_schedule()){
            otherEntryIds.push(entry_id)
          }
        })
      }
    })

    return otherEntryIds
  }

  #to_options(entry_ids) {
    let options = []
    entry_ids.forEach(id => {
      const entry = this.allEntriesMap.get(id)
      if (entry.is_schedule()) {
        options.push({key: entry.key, value: entry.value, text: entry.text, icon: 'bus'})
      } else {
        options.push({key: entry.key, value: entry.value, text: entry.text})
      }
    })
    return options
  }
}



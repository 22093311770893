/*
 * This EntryGenerator helps during the development
 * It generates the entries for the staff days
 */
import Entry from "./Entry";

export default class Generator {
  constructor({staffs, days, schedules, presettings, service_staff_array}) {
    this.staffs = staffs
    this.days = days
    this.schedules = schedules
    this.presettings = presettings
    this.service_staff_array = service_staff_array
    this.allEntries = []
    this.open_assignment_array = [] // [[staff-id-day, ["s-1","s-2"]],[]]
    this.assignment_array = [] // [[staff-id-day, [ids]]]

    this.#generateAssignmentMap()
    this.#generateAllEntries()
    this.#generateAssignmentMap()
    this.#generateOpenAssignment()
  }

  #generateOpenAssignment() {
    this.staffs.forEach(staff => {
      this.days.forEach(day => {
        const entry_ids = this.allEntries.map(entry => entry.key)
        this.open_assignment_array.push([`${staff.id}-${day}`, entry_ids])
      })
    })
  }

  #generateAssignmentMap() {
    this.service_staff_array.forEach(element => {
      let entry_ids = []
      element[1].forEach(x => {
        entry_ids.push(x.id)
      })
      this.assignment_array.push([element[0], entry_ids])
    })
  }

  #generateAllEntries() {
    this.schedules.forEach(schedule => {
      this.allEntries.push(new Entry({schedule: schedule}))
    })

    this.presettings.forEach(presetting => {
      this.allEntries.push(new Entry({presetting: presetting}))
    })
  }
}

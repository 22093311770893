export default class Entry {
  constructor({schedule = null, presetting = null}) {
    this.schedule = schedule // Attention: this is schedule_select not schedule
    this.presetting = presetting // Attention: this is presetting_select not presetting
    this.key = undefined
    this.value = undefined
    this.text = undefined


    this.#initKey()
    this.#initValue()
    this.#initText()
  }

  #initKey() {
    if (this.schedule) {
      //this.key = `s-${this.schedule.id}`
      this.key = this.schedule.key
    } else if (this.presetting) {
      //this.key = `p-${this.presetting.id}`
      this.key = this.presetting.key
    }
  }

  #initValue() {
    this.value = this.key
  }

  #initText() {
    if (this.schedule !== null) {
      //this.text = this.schedule.matchcode
      this.text = this.schedule.text
    } else if (this.presetting !== null) {
      //this.text = this.presetting.matchcode
      this.text = this.presetting.text
    }
  }

  is_schedule() {
    if (this.schedule) {
      return true
    } else {
      return false
    }
  }

  is_presetting() {
    if (this.presetting) {
      return true
    } else {
      return false
    }
  }

}

import {Form, Grid} from "semantic-ui-react";
import React from "react";

export default function WeekIterationControl(props){

  const {iteration, changeIteration, disabled} = props
  return <>
    <Grid.Column width={3}>
        <Form.Input name='iteration' label='Anzahl Wochen' value={iteration}
                    onChange={changeIteration} type="number" disabled={disabled}/>
    </Grid.Column>
  </>
}
